import observe from '../utils/observe';
import sequence from '../utils/sequence';
import { doLog } from '../actions/log';


const RESPONSIVENESS = 'responsiveness';

let isInfoCalled = false;
const {report, result} = sequence(window, RESPONSIVENESS, RESPONSIVENESS);
window.addEventListener('info-called', () => isInfoCalled = true);


/**
 * PerformanceObserver callback for responsiveness (type "event")
 * @param {boolean} isLogging
 * @returns {void}
 */
export const measureResponsiveness = (isLogging) => {
    const interactionMap = {};
    
    let numOfResponsivenessEvents = 0;
    let worstLatency = 0;
    let worstLatencyOverBudget = 0;
    let totalLatencyOverBudget = 0;

    /**
     * PerformanceObserver callback for responsiveness (type "event")
     * @param {PerformanceEntryList} entries
     * @returns {void}
     */
    return (entries) => {
        const currentInteractionIds = new Set();

        for (const entry of entries) {
            if (entry.interactionId > 0) {
                currentInteractionIds.add(entry.interactionId);

                if (!interactionMap[entry.interactionId]) {
                    numOfResponsivenessEvents++;
                    interactionMap[entry.interactionId] = [];
                }

                interactionMap[entry.interactionId].push(entry);
            }
        }
        
        Array.from(currentInteractionIds).forEach((interactionId) => {
            const {
                eventNames, 
                elementType, 
                latency, 
                latencyOverBudget,
                target,
            } = extractingResponsivenessEventDetails(interactionMap[interactionId]);

            worstLatency = Math.max(worstLatency, latency);
            worstLatencyOverBudget = Math.max(latencyOverBudget, worstLatencyOverBudget);

            if (latencyOverBudget > 0) {
                totalLatencyOverBudget += latencyOverBudget;
            }
            
            const currentResponsivenessMeasure = {
                entryType: RESPONSIVENESS,
                worstLatency,
                worstLatencyOverBudget,
                totalLatencyOverBudget,
                numOfResponsivenessEvents,
                eventNames: eventNames,
                elementType: elementType,
            };
            
            if (latencyOverBudget === worstLatencyOverBudget) {
                report(currentResponsivenessMeasure);
            }

            const queryParams = new URLSearchParams(window.location.search);
            const isDebugQueryParamOn = queryParams.get('debug') === 'true';
            
            if (isLogging || isInfoCalled || isDebugQueryParamOn) {
                doLog({
                    ...currentResponsivenessMeasure, 
                    element: target,
                    currentLatency: latency,
                    currentLatencyOverBudget: latencyOverBudget,
                });
            }
            
        });
    };
};

/**
 * PerformanceObserver callback for responsiveness (type "event")
 * @param {PerformanceEntryList[]} eventEntries
 * @returns {{eventNames: string[], elementType: string | undefined, latency: number, latencyOverBudget: number, target: Element | undefined }}
 */
const extractingResponsivenessEventDetails = (eventEntries) => {
    const allLatencies = eventEntries.map(entry => entry.duration);
    const eventNames = eventEntries.map(entry => entry.name);
    const target = eventEntries.map(entry => entry.target).filter(target => !!target);
    const elementType =  target[0]?.nodeName.toLowerCase();


    const latency = Math.max(...allLatencies);
    const budget = eventNames.some(event => event.includes('key')) ? 50 : 100;
    const latencyOverBudget = Math.max(latency - budget, 0);

    return {
        eventNames,
        latency,
        latencyOverBudget,
        target,
        elementType,
    };
};

/**
 * @param {import('./utils/utils.js').State} state 
 * @param {boolean} isLogging
 */
export const startMeasureResponsiveness = (isLoggingEnabled) => {
    observe(window.PerformanceObserver, 'event', measureResponsiveness(isLoggingEnabled), true, { durationThreshold: 50 });
    
    return result;
};